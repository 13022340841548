<template>
  <div id="content"
       style="min-width: 740px;position: relative; width: calc(100% - 285px); left: 256px; top: 100px; margin: 0px 15px; background-color: white; padding: 20px 15px; border-radius: 5px;box-shadow: 5px 4px 2px 0 rgb(60 75 100 / 14%), 0 3px 1px -2px rgb(60 75 100 / 12%), 0 1px 5px 0 rgb(60 75 100 / 20%);">
    <v-container style="max-width: none">
      <v-row>
        <v-col cols="12" style="padding: 0">
          <v-col cols="12">
            <v-card outlined tile style="padding: 6px;">
              <div class="search-container">
                <div class="search-title">원하는 정보를 검색하세요</div>
                <input type="text"
                       v-model="query"
                       @keyup.enter="pushToSearchResult()"
                       placeholder="검색어 입력...">

                <button
                    v-on:click="pushToSearchResult()">
                  검색
                </button>
              </div>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventBus from "@/EventBus";
import axios from "axios";
import {dataSetCodeConverter} from "@/mixins/dataSetCodeConverter";

export default {
  name: "SearchContent",
  mixins: [dataSetCodeConverter],
  components: {},
  created() {
  },
  data() {
    return {
      query: "",
      dataSet: null
    };
  },
  methods: {
    pushToSearchResult: function () {
      // 뒤로가기 눌렀을 때 마지막으로 클릭했던 단어 유지
      this.$router
          .push({
            query: {
              dataSet: this.dataSet,
              query: this.query.replace(/\s+/g, ' ')
            }
          })
          .catch(err => {
          });

      this.$router.push({
        name: "SearchResult",
        query: {
          dataSet: this.$store.getters.getSelectedDataSet,
          query: this.query.replace(/\s+/g, ' ')
          // q: this.q
          // section: this.section,
          // firstCate: this.firstCate,
          // secondCate: this.secondCate,
          // thirdCate: this.thirdCate
        }
      });
    },
  }
};
</script>

<style scoped>

.search-container {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.search-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

input[type="text"]:hover, input[type="text"]:focus {
  border-color: #1a73e8; /* 파란색으로 변경 */
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0f57a1; /* 더 진한 파란색으로 변경 */
}

</style>
